@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url(assets/fonts/Raleway-Light.ttf) format("truetype");
}

* {
  margin: 0;
  padding: 0;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E5E6EB;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #B3B6BE;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;

}

.pac-container {
  z-index: 9999 !important;
}

/* *For Resize Column Cursor */
.resize-cursor {
  height: 100% !important;
  width: 10px !important;
  border-right: 8px double #000;
  margin-right: 10px;
  padding: 0px !important;
  cursor: col-resize !important;
  background-image: none !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

